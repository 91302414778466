<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      :label-position="'top'"
      v-if="loading"
      class="pd-b-3"
    >
      <div>
        <router-link :to="routerBack" class="text-grey-light font-18"
          ><i class="el-icon-arrow-left"></i> ย้อนกลับ</router-link
        >
      </div>

      <h3 class="text-center">{{ edit ? "แก้ไข" : "เพิ่ม" }}ข้อมูลบริษัท</h3>
      <el-form-item label="สถานะ" v-if="user != null && user.role == 50">
        <el-select
          v-model="ruleForm.status"
          placeholder="กรุณาเลือกสถานะ"
          id="position"
          class="w-100"
        >
          <el-option
            v-for="data in statusOption"
            :label="data.label"
            :value="data.value"
            :key="data.label"
            ><div class="text-over">{{ data.label }}</div></el-option
          >
        </el-select>
      </el-form-item>
      <el-form-item label="ชื่อบริษัท" prop="companyName">
        <div>
          <el-input id="companyName" v-model="ruleForm.companyName"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="ชื่อบริษัท (แก้ไข)" class="edit-label">
        <div>
          <el-input
            id="companyNameEdit"
            v-model="ruleForm.companyNameEdit"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="เลขนิติบุคคล" :prop="'juristicId'">
        <el-input
          id="juristicId"
          v-model="ruleForm.juristicId"
          maxlength="13"
          :disabled="disableCop"
          v-mask="'#############'"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="เลขนิติบุคคล (แก้ไข)"
        prop="corporateNumber"
        class="edit-label"
      >
        <div>
          <el-button
            type="primary"
            circle
            :disabled="ruleForm.corporateNumber.length < 13"
            size="mini"
            @click="sysDbdByUserId($route.params.id)"
          >
            <i class="fas fa-sync-alt cr-pointer"></i>
          </el-button>
          <span
            class="text-right mg-b-5 text-grey-light font-16"
            style="display: inline-block; float: right"
          >
            ข้อมูลจาก DBD อัปเดตล่าสุดเมื่อ
            {{ ruleForm.lastSyncDBD | dateTimeTh }}
          </span>
          <el-input
            id="corporateNumber"
            v-model="ruleForm.corporateNumber"
            maxlength="13"
            v-mask="'#############'"
          ></el-input>
        </div>
      </el-form-item>
      <!-- <el-form-item label="ตำแหน่ง" prop="position">
      <el-select
        v-model="ruleForm.position"
        placeholder="กรุณาเลือกตำแหน่ง"
        id="position"
        class="w-100"
      >
        <el-option
          v-for="data in positionOption"
          :label="data.label"
          :value="data.value"
          :key="data.label"
          ><div class="text-over">{{ data.label }}</div></el-option
        >
      </el-select>
    </el-form-item> -->
      <el-form-item label="ลักษณะธุรกิจ" prop="businessType">
        <el-select
          id="businessType"
          v-model="ruleForm.businessType"
          placeholder="กรุณาเลือกลักษณะธุรกิจ"
          @change="
            (ruleForm.cateIndustryType = null),
              (ruleForm.industryType = null),
              (ruleForm.industryTypeEtc = '')
          "
          class="w-100"
        >
          <el-option
            v-for="data in businessTypeOption"
            :label="data.label"
            :value="data.value"
            :key="data.label"
          >
            <div class="text-over">{{ data.label }}</div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="ประเภทของอุตสาหกรรม" prop="cateIndustryType">
        <div>
          <el-select
            id="cateIndustryType"
            v-model="ruleForm.cateIndustryType"
            @change="
              ruleForm.cateIndustryType == 28
                ? (ruleForm.industryType = 0)
                : (ruleForm.industryType = null)
            "
            placeholder="กรุณาเลือกประเภทของอุตสาหกรรม"
            class="w-100"
            filterable
            no-match-text="ไม่พบตัวเลือก"
          >
            <!-- id 26 และ 27 ปิดไว้ไม่ให้กดเลือก เพราะหน้าสมัครไม่โชว์แล้ว -->
            <el-option
              v-for="(data, index) in industryTypeOption"
              :label="data.label"
              :value="data.id"
              :key="index"
              :disabled="data.id == 26 || data.id == 27"
            >
              <div class="text-over">{{ data.label }}</div>
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item
        prop="industryType"
        v-if="ruleForm.cateIndustryType != 28 && ruleForm.cateIndustryType"
        class=""
      >
        <el-select
          id="industryType"
          :disabled="ruleForm.cateIndustryType == null"
          v-model="ruleForm.industryType"
          placeholder="กรุณาเลือกประเภทย่อยของอุตสาหกรรม"
          class="w-100 mg-t-5"
          filterable
          no-match-text="ไม่พบตัวเลือก"
        >
          <el-option
            v-for="(data, index) in subIndustry"
            :label="data.label"
            :value="data.value"
            :key="index"
          >
            <div class="text-over">{{ data.label }}</div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="ruleForm.industryType == 0" prop="industryTypeEtc">
        <el-input
          id="industryTypeEtc"
          v-model="ruleForm.industryTypeEtc"
          placeholder="กรุณากรอกประเภทของอุตสาหกรรม"
        ></el-input>
      </el-form-item>
      <el-form-item label="หน่วยงานที่เป็นสมาชิก" prop="memberOf">
        <div>
          <el-select
            id="memberOf"
            v-model="ruleForm.memberOf"
            placeholder="กรุณาเลือกหน่วยงาน"
            class="w-100"
            multiple
            @change="checkDisable"
          >
            <div class="pd-x-5">
              <el-option
                v-for="item in fucFilter(memberOrganizeOption, 'group', 1)"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disable"
              >
                <div class="is-flex ai-center">
                  <div class="circle-select"></div>
                  {{ item.label }}
                </div>
              </el-option>
            </div>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item v-if="checkmember(ruleForm.memberOf)" prop="memberOfEtc">
        <div>
          <el-input
            id="memberOfEtc"
            :disabled="ruleForm.memberOfEtc == 'EXAC'"
            v-model="ruleForm.memberOfEtc"
            placeholder="กรุณากรอกหน่วยงาน"
          ></el-input>
        </div>
      </el-form-item>
      <!-- <el-form-item label="การจ้างงาน" prop="employment">
      <div>
        <el-select
          id="employment"
          v-model="ruleForm.employment"
          placeholder="การจ้างงาน"
          class="w-100"
        >
          <el-option
            v-for="data in employmentOption"
            :label="data.label"
            :value="data.value"
            :key="data.label"
            ><div class="text-over">{{ data.label }}</div></el-option
          >
        </el-select>
      </div>
    </el-form-item> -->
      <!-- <el-form-item label="ปีที่จัดตั้งบริษัท (พ.ศ.)" prop="yearEstablished">
      <div v-if="user == null || edit || user.role == 0">
        <el-input
          maxlength="4"
          v-model.number="ruleForm.yearEstablished"
          :disabled="user != null && user.role != 0"
        ></el-input>
      </div>
    </el-form-item> -->
      <el-form-item
        label="รายได้ประจำปีล่าสุดของบริษัท (บาท)"
        prop="latestAnnualRevenues"
      >
        <div class="el-input">
          <vue-numeric
            id="latestAnnualRevenues"
            :precision="2"
            read-only-class="editor-contents el-input__inner"
            v-model="ruleForm.latestAnnualRevenues"
            minus
            class="editor-contents el-input__inner"
          ></vue-numeric>
        </div>
      </el-form-item>

      <!-- <el-form-item
      label="กำไรสุทธิประจำปีล่าสุดของบริษัท (บาท)"
      prop="latestAnnualProfits"
    >
      <div
        class="el-input"
        :class="{ 'is-disabled': user != null && user.role != 0 }"
        v-if="user == null || edit || user.role == 0"
      >
        <vue-numeric
          :read-only="user != null && user.role != 0"
          :precision="2"
          read-only-class="editor-contents el-input__inner"
          v-model="ruleForm.latestAnnualProfits"
          minus
          class="editor-contents el-input__inner"
        ></vue-numeric>
      </div>
    </el-form-item> -->
      <el-form-item label="ประเภทของผู้ประกอบการ " prop="operatorType">
        <div>
          <el-select
            id="operatorType"
            v-model="ruleForm.operatorType"
            placeholder="กรุณาเลือกประเภทของผู้ประกอบการ "
            class="w-100"
            @change="changeOperatorType"
          >
            <el-option
              v-for="data in operatorTypeOption"
              :label="data.label"
              :value="data.value"
              :key="data.label"
              ><div class="text-over">{{ data.label }}</div></el-option
            >
          </el-select>
        </div>
      </el-form-item>
      <div v-if="ruleForm.operatorType == 1">
        <el-form-item
          label="มีการผลิต หรือ ขายให้กับผู้ส่งออกโดยตรงหรือไม่"
          prop="isProduction"
        >
          <el-radio-group v-model="ruleForm.isProduction">
            <el-radio
              v-for="data in isProductionOption"
              :key="data.value"
              :label="data.value"
              >{{ data.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="ภายในปีนี้มีแผนจะเป็นผู้ส่งออกโดยตรงหรือไม่ "
          prop="exportPlan"
        >
          <el-radio-group
            v-model="ruleForm.exportPlan"
            @change="exportPlanChange"
          >
            <el-radio
              v-for="data in exportPlanOption"
              :key="data.value"
              :label="data.value"
              >{{ data.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
      </div>
      <div v-else-if="ruleForm.operatorType == 2 || ruleForm.operatorType == 3">
        <el-form-item label="ประสบการณ์ในการส่งออก" prop="exportExperience">
          <el-select
            id="exportExperience"
            v-model="ruleForm.exportExperience"
            placeholder="กรุณาเลือกประสบการณ์ในการส่งออก"
            class="w-100"
          >
            <el-option
              v-for="data in exportExperienceOption"
              :label="data.label"
              :value="data.value"
              :key="data.value"
              ><div class="text-over">{{ data.label }}</div></el-option
            >
          </el-select>
        </el-form-item>
        <div>
          <el-form-item label="สัดส่วนในการส่งออก" prop="exportRatio">
            <el-select
              id="exportRatio"
              v-model="ruleForm.exportRatio"
              placeholder="กรุณาเลือกสัดส่วนในการส่งออก"
              class="w-100"
            >
              <el-option
                v-for="data in exportRatioOption"
                :label="data.label"
                :value="data.value"
                :key="data.label"
                ><div class="text-over">{{ data.label }}</div></el-option
              >
            </el-select>
          </el-form-item>
          <el-form-item
            label="ความสม่ำเสมอของการสั่งซื้อจากผู้ซื้อต่างประเทศรายเดิม"
            prop="orderConsistency"
          >
            <el-select
              id="orderConsistency"
              v-model="ruleForm.orderConsistency"
              placeholder="กรุณาเลือกความสม่ำเสมอของการสั่งซื้อจากผู้ซื้อต่างประเทศรายเดิม"
              class="w-100"
            >
              <el-option
                v-for="data in orderConsistencyOption"
                :label="data.label"
                :value="data.value"
                :key="data.label"
                ><div class="text-over">{{ data.label }}</div></el-option
              >
            </el-select>
          </el-form-item>
          <el-form-item
            label="ความถี่ในการส่งออกโดยเฉลี่ย"
            prop="exportFrequency"
          >
            <el-select
              id="exportFrequency"
              v-model="ruleForm.exportFrequency"
              placeholder="กรุณาเลือกความถี่ในการส่งออกโดยเฉลี่ย"
              class="w-100"
            >
              <el-option
                v-for="data in exportFrequencyOption"
                :label="data.label"
                :value="data.value"
                :key="data.label"
                ><div class="text-over">{{ data.label }}</div></el-option
              >
            </el-select>
          </el-form-item>
          <el-form-item
            label="วงเงินค้าขายต่อ Shipment โดยเฉลี่ย"
            prop="shipment"
          >
            <el-select
              id="shipment"
              v-model="ruleForm.shipment"
              placeholder="กรุณาเลือกวงเงินค้าขายต่อ Shipment โดยเฉลี่ย"
              class="w-100"
            >
              <el-option
                v-for="data in shipmentOption"
                :label="data.label"
                :value="data.value"
                :key="data.label"
                :disabled="data.disable"
                v-if="!edit && data.disable ? false : true"
                ><div class="text-over">
                  {{ data.label }}
                </div></el-option
              >
            </el-select>
          </el-form-item>
          <el-form-item label="เทอมการชำระเงินโดยเฉลี่ย" prop="paymentTerm">
            <el-select
              id="paymentTerm"
              v-model="ruleForm.paymentTerm"
              placeholder="กรุณาเลือกเทอมการชำระเงินโดยเฉลี่ย"
              class="w-100"
            >
              <el-option
                v-for="data in paymentTermOption"
                :label="data.label"
                :value="data.value"
                :key="data.label"
                ><div class="text-over">{{ data.label }}</div></el-option
              >
            </el-select>
          </el-form-item>
          <el-form-item label="มูลค่าการส่งออกโดยเฉลี่ยต่อปี" prop="exportValue">
            <el-select
              id="exportValue"
              v-model="ruleForm.exportValue"
              placeholder="กรุณาเลือกมูลค่าการส่งออกโดยเฉลี่ยต่อปี"
              class="w-100"
            >
              <el-option
                v-for="data in exportValueOption"
                :label="data.label"
                :value="data.value"
                :key="data.label"
                :disabled="data.disable"
                v-if="!edit && data.disable ? false : true"
                ><div class="text-over">{{ data.label }}</div></el-option
              >
            </el-select>
          </el-form-item>
          <el-form-item label="จำนวนประเทศที่ทำการส่งออก" prop="exportCountry">
            <el-select
              id="exportCountry"
              v-model="ruleForm.exportCountry"
              placeholder="กรุณาเลือกจำนวนประเทศที่ทำการส่งออก"
              class="w-100"
            >
              <el-option
                v-for="data in exportCountryOption"
                :label="data.label"
                :value="data.value"
                :key="data.label"
                ><div class="text-over">{{ data.label }}</div></el-option
              >
            </el-select>
          </el-form-item>
        </div>
        <el-form-item
          v-if="!openMenu"
          label="ความคาดหวังจากการเข้าอบรม"
          prop="trainingExpectations"
        >
          <el-select
            id="trainingExpectations"
            v-model="ruleForm.trainingExpectations"
            placeholder="ความคาดหวังจากการเข้าอบรม"
            class="w-100"
          >
            <el-option
              v-for="data in trainingExpectationsOption"
              :label="data.label"
              :value="data.value"
              :key="data.label"
              ><div class="text-over">{{ data.label }}</div></el-option
            >
          </el-select>
        </el-form-item>
      </div>
      <el-form-item
        label="ร้อยละที่คาดหวังว่าจะส่งออกได้ / ส่งออกได้เพิ่มขึ้น"
        prop="expectedPercentage"
        v-if="
          (ruleForm.operatorType &&
            !closeByExportPlan &&
            ruleForm.exportPlan == 1) ||
          ruleForm.operatorType == 3
        "
      >
        <el-select
          id="expectedPercentage"
          v-model="ruleForm.expectedPercentage"
          placeholder="กรุณาเลือกร้อยละที่คาดหวังว่าจะส่งออกได้"
          class="w-100"
        >
          <el-option
            v-for="data in expectedPercentageOption"
            :label="data.label"
            :value="data.value"
            :key="data.label"
            ><div class="text-over">{{ data.label }}</div></el-option
          >
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.expectedPercentage == 5"
        prop="expectedPercentageEtc"
      >
        <vue-numeric
          id="expectedPercentageEtc"
          read-only-class="editor-contents el-input__inner is-disabled"
          currency="ร้อยละ"
          v-model="ruleForm.expectedPercentageEtc"
          class="editor-contents el-input__inner"
        ></vue-numeric>
      </el-form-item>

      <div class="text-center">
        <el-button
          type="primary"
          :loading="loadingBtn"
          @click="saveEditdata"
          class="mg-t-5"
          >บันทึก</el-button
        >
      </div>
    </el-form>
  </div>
</template>
<script>
import { HTTP } from "@/service/axios";
import "@/service/Company";
export default {
  computed: {
    subIndustry() {
      let filterSub = this.$store.state.industryType.filter(
        a =>
          this.ruleForm.cateIndustryType != null &&
          a.id === this.ruleForm.cateIndustryType
      );
      // this.ruleForm.industryType = null;
      if (filterSub.length > 0) {
        return filterSub[0].subIndustry;
      } else {
        return [];
      }
    },
    statusOption() {
      return this.$store.state.statusList;
    },
    positionOption() {
      return this.$store.state.position;
    },
    businessTypeOption() {
      return this.$store.state.businessType;
    },
    industryTypeOption() {
      let fitler = this.$store.state.industryType;
      if (this.$router.currentRoute.name == "Signup") {
        fitler = fitler.filter(a => a.id != 26 && a.id != 27);
      }
      return fitler;
    },
    employmentOption() {
      return this.$store.state.employment;
    },
    exportExperienceOption() {
      return this.$store.state.exportExperience;
    },
    exportRatioOption() {
      return this.$store.state.exportRatio;
    },
    orderConsistencyOption() {
      return this.$store.state.orderConsistency;
    },
    exportFrequencyOption() {
      return this.$store.state.exportFrequency;
    },
    exportCountryOption() {
      return this.$store.state.exportCountry;
    },
    trainingExpectationsOption() {
      return this.$store.state.trainingExpectations;
    },
    expectedPercentageOption() {
      return this.$store.state.expectedPercentage;
    },
    memberOrganizeOption() {
      return this.$store.state.memberOfOrganize;
    },
    operatorTypeOption() {
      return this.$store.state.operatorType;
    },
    exportPlanOption() {
      return this.$store.state.exportPlan;
    },
    isProductionOption() {
      return this.$store.state.isProduction;
    },
    shipmentOption() {
      return this.$store.state.shipment;
    },
    paymentTermOption() {
      return this.$store.state.paymentTerm;
    },
    exportValueOption() {
      return this.$store.state.exportValue;
    },
    openMenu() {
      return this.$store.state.openMenu;
    }
  },
  props: {
    routerBack: {
      type: String,
      default: "/"
    },
    ruleForm: {
      type: Object,
      default: {}
    },
    edit: {
      type: Boolean,
      default: false
    },
    validate: {
      type: Boolean,
      default: true
    },
    typePage: {
      type: String,
      default: ""
    }
  },
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("กรุณากรอกปีที่จัดตั้งบริษัท"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("กรุณากรอกเฉพาะตัวเลข"));
        } else {
          callback();
        }
      }, 250);
    };

    var checkisProduction = (rule, value, callback) => {
      //console.log(value);

      if (this.ruleForm.exportPlan == 2) {
        if (value == null) {
          return callback();
        }
      } else {
        //console.log(value);
        if (!value) {
          return callback(
            new Error("กรุณาเลือกร้อยละที่คาดหวังว่าจะส่งออกได้")
          );
        } else {
          return callback();
        }
      }
    };
    return {
      loadSyncDBD: false,
      closeByExportPlan: false,
      defaultCompany: null,
      companyEdit: false,
      defaultCop: null,
      copNumberEdit: false,
      widthMax: window.innerWidth,
      ruleData: null,
      disableCop: false,
      size: "",
      loading: false,
      loadingBtn: false,
      confirmReset: false,
      copCheck: 0,
      companyLoading: false,
      money: {
        decimal: ".",
        thousands: ",",
        precision: 0,
        masked: false
      },
      rules: {
        companyName: [
          {
            required: this.validate,
            message: "กรุณากรอกชื่อบริษัท",
            trigger: "blur"
          }
        ],
        corporateNumber: [
          {
            // required: this.validate,

            message: "กรุณากรอกเลขนิติบุคคล",
            trigger: "blur"
          },
          {
            min: 13,
            max: 13,
            message: "กรุณากรอกเลขนิติบุคคล 13 หลัก",
            trigger: "blur"
          }
        ],
        juristicId: [
          {
            required: this.validate,
            message: "กรุณากรอกเลขนิติบุคคล",
            trigger: "blur"
          },
          {
            min: 13,
            max: 13,
            message: "กรุณากรอกเลขนิติบุคคล 13 หลัก",
            trigger: "blur"
          }
        ],
        position: [
          {
            required: this.validate,
            message: "กรุณากรอกตำแหน่ง",
            trigger: "blur"
          }
        ],
        businessType: [
          {
            required: this.validate,
            message: "กรุณาเลือกลักษณะธุรกิจ",
            trigger: "blur"
          }
        ],
        cateIndustryType: [
          {
            required: this.validate,
            message: "กรุณาเลือกประเภทของอุตสาหกรรม",
            trigger: "blur"
          }
        ],
        industryType: [
          {
            required: this.validate,
            message: "กรุณาเลือกประเภทย่อยของอุตสาหกรรม",
            trigger: "blur"
          }
        ],
        industryTypeEtc: [
          {
            required: this.validate,
            message: "กรุณากรอกประเภทของอุตสาหกรรม",
            trigger: "blur"
          }
        ],
        memberOf: [
          {
            required: this.validate,
            message: "กรุณาเลือกหน่วยงาน",
            trigger: "blur"
          }
        ],
        memberOfEtc: [
          {
            required: this.validate,
            message: "กรุณากรอกหน่วยงาน",
            trigger: "blur"
          }
        ],
        employment: [
          {
            required: this.validate,
            message: "กรุณาเลือกจำนวนพนักงาน",
            trigger: "blur"
          }
        ],
        yearEstablished: [
          {
            required: this.validate,
            message: "กรุณากรอกปีที่จัดตั้งบริษัท",
            trigger: "blur"
          },
          { validator: checkNum, trigger: "blur" }
        ],
        latestAnnualRevenues: [
          {
            required: this.validate,
            message: "กรุณากรอกรายได้ประจำปีล่าสุดของบริษัท (บาท)",
            trigger: "blur"
          }
          // { type: "number", message: "กรุณากรอกเฉพาะตัวเลข" },
        ],
        latestAnnualProfits: [
          {
            required: this.validate,
            message: "กรุณากรอกกำไรสุทธิประจำปีล่าสุดของบริษัท (บาท)",
            trigger: "blur"
          }
          // { type: "number", message: "กรุณากรอกเฉพาะตัวเลข" },
        ],
        exportExperience: [
          {
            required: this.validate,
            message: "กรุณาเลือกประสบการณ์ในการส่งออก",
            trigger: "blur"
          }
        ],
        exportRatio: [
          {
            // required: this.validate,
            required: false,
            message: "กรุณาลือกสัดส่วนในการส่งออก",
            trigger: "blur"
          }
        ],
        orderConsistency: [
          {
            // required: this.validate,
            required: false,
            message:
              "กรุณาเลือกความสม่ำเสมอของการสั่งซื้อจากผู้ซื้อต่างประเทศรายเดิม",
            trigger: "blur"
          }
        ],
        exportFrequency: [
          {
            // required: this.validate,
            required: false,
            message: "กรุณาเลือกความถี่ในการส่งออกโดยเฉลี่ย",
            trigger: "blur"
          }
        ],
        exportCountry: [
          {
            // required: this.validate,
            required: false,
            message: "กรุณาเลือกจำนวนประเทศที่ทำการส่งออก",
            trigger: "blur"
          }
        ],
        // trainingExpectations: [
        //   {
        //     required:  this.validate,
        //     message: "กรุณาเลือกความถี่ในการส่งออกโดยเฉลี่ย",
        //     trigger: "blur",
        //   },
        // ],
        expectedPercentage: [
          {
            // required: this.validate,
            required: false,
            // validator: checkisProduction,
            trigger: "blur"
          }
        ],
        expectedPercentageEtc: [
          {
            required: this.validate,
            message: "กรุณากรอกร้อยละที่คาดหวังว่าจะส่งออกได้",
            trigger: "blur"
          }
          // { type: "number", message: "กรุณากรอกเฉพาะตัวเลข" },
        ],
        operatorType: [
          {
            required: this.validate,
            message: "กรุณาเลือกประเภทของผู้ประกอบการ",
            trigger: "blur"
          }
        ],
        exportPlan: [
          {
            required: this.validate,
            message: "กรุณาเลือกแผนการส่งออก",
            trigger: "blur"
          }
        ],
        isProduction: [
          {
            required: this.validate,
            message: "กรุณาเลือกการผลิต หรือ ขายให้กับผู้ส่งออก",
            trigger: "blur"
          }
        ],
        shipment: [
          {
            required: this.validate,
            message: "กรุณาเลือกวงเงินค้าขายต่อ Shipment โดยเฉลี่ย",
            trigger: "blur"
          }
        ],
        paymentTerm: [
          {
            required: this.validate,
            message: "กรุณาเลือกเทอมการชำระเงินโดยเฉลี่ย",
            trigger: "blur"
          }
        ],
        exportValue: [
          {
            required: this.validate,
            message: "กรุณาเลือกมูลค่าการส่งออกโดยเฉลี่ยต่อปี",
            trigger: "blur"
          }
        ]
      },
      checkRole: false
    };
  },
  async mounted() {
    this.disableCop =
      this.edit && this.ruleForm.juristicId.length == 13 ? true : false;
    let obj = this.memberOrganizeOption;
    obj.map(data => (data.disable = false));
    this.$store.commit("SET_MEMBEROF", obj);
    if (this.user == null) {
      this.fetchStatusMenuSignUp();
    } else {
      if (this.user.role == 0) {
        this.checkRole = await this.checkCompanyData(this.user);
      }
      this.fetchStatusMenu();
    }
    this.checkDisable(false);
  },
  watch: {
    ruleForm() {
      this.checkDisable(false);
      if (this.edit) {
        this.size = this.checkSize(this.ruleForm.latestAnnualRevenues);
        if (this.ruleForm.exportPlan == 2) {
          this.ruleForm.expectedPercentage = "";
        }
      }
    }
  },
  methods: {
    async sysDbdByUserId(id) {
      this.$emit("emitfullLoading", true);
      HTTP.defaults.headers.common.Authorization = `Bearer ${ this.token }`;
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      let result = await HTTP.post(`system/admin/request/dbd/juristicId`, {
        userId: id
      })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
        })
        .finally(() => {});
      if (result.data.success) {
        if (result.data.dbd) {
          this.$message({
            message: "Sync ข้อมูลสำเร็จ",
            type: "success"
          });
        } else {
          this.$message.error({
            message: "เลขนิติบุคคลนี้ไม่มีข้อมูลตาม DBD"
          });
        }
      }
      // console.log("bbbbbbb");
      this.$emit("getNewData");
    },
    exportPlanChange() {
      if (this.ruleForm.exportPlan == 2) {
        this.closeByExportPlan = true;
        this.ruleForm.expectedPercentage = null;
      } else {
        this.closeByExportPlan = false;
      }
    },
    changeOperatorType() {
      this.closeByExportPlan = false;
      this.ruleForm.exportPlan = null;
      this.ruleForm.isProduction = null;
      this.ruleForm.exportExperience = null;
      this.ruleForm.exportRatio = null;
      this.ruleForm.orderConsistency = null;
      this.ruleForm.exportFrequency = null;
      this.ruleForm.shipment = null;
      this.ruleForm.paymentTerm = null;
      this.ruleForm.exportValue = null;
      this.ruleForm.exportCountry = null;
      this.ruleForm.expectedPercentage = null;
    },
    fucFilter(data, param1 = "value", param2) {
      let obj = data.filter(a => a[param1] == param2);
      if (obj.length > 0) {
        return obj;
      } else {
        return "";
      }
    },
    checkSize(money) {
      if (this.ruleForm.businessType === 2 && money < 100000000) {
        return "s";
      } else if (this.ruleForm.businessType === 2 && money <= 500000000) {
        return "m";
      } else if (this.ruleForm.businessType === 2 && money > 500000000) {
        return "l";
      } else if (this.ruleForm.businessType !== 2 && money <= 50000000) {
        return "s";
      } else if (this.ruleForm.businessType !== 2 && money <= 300000000) {
        return "m";
      } else if (this.ruleForm.businessType !== 2 && money > 300000000) {
        return "l";
      }
    },
    checkDisable(chang = true) {
      // console.log("checkDisable");
      let index = this.ruleForm.memberOf.findIndex(a => a == 7);
      let check = this.ruleForm.memberOf.findIndex(a => a != 7);
      if (index > -1) {
        let obj = this.memberOrganizeOption;
        obj.map(data => (data.value != 7 ? (data.disable = true) : null));
        this.$store.commit("SET_MEMBEROF", obj);
        this.ruleForm.memberOfEtc = "EXAC";
      } else if (check > -1) {
        let obj = this.memberOrganizeOption;
        obj.map(data => (data.value == 7 ? (data.disable = true) : false));
        this.$store.commit("SET_MEMBEROF", obj);
        if (chang) {
          this.ruleForm.memberOfEtc = "";
        }
      } else {
        let obj = this.memberOrganizeOption;
        obj.map(data => (data.disable = false));
        this.$store.commit("SET_MEMBEROF", obj);
      }
    },
    checkmember(data) {
      if (data != null) {
        let index = data.findIndex(a => a == 6);
        let check = data.findIndex(a => a == 7);
        if (check > -1) {
          return true;
        } else if (index > -1) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    fetchStatusMenuSignUp() {
      HTTP.get(`sign/up/close/function`)
        .then(res => {
          if (res.data.success) {
            let status = res.data.obj.status == 1 ? true : false;
            this.$store.commit("SET_OPEN_MENU", status);
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("FETCH_OPEN_MENU error", e.response);
        });
      this.loading = true;
    },
    fetchStatusMenu() {
      let obj = {
        token: this.token
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${obj.token}`;
      HTTP.get(`close/function`)
        .then(res => {
          if (res.data.success) {
            let status = res.data.obj.status == 1 ? true : false;
            this.$store.commit("SET_OPEN_MENU", status);
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("FETCH_OPEN_MENU error", e.response);
        });
      this.loading = true;
    },
    async checkCompany() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
          await HTTP.post("coporate", {
            corporateNumber: this.ruleForm.corporateNumber
          })
            .then(async res => {
              //console.log(res);
              if (
                res.data.data == "This company number doesn`t exist yet." ||
                this.ruleForm.corporateNumber == "0000000000000"
              ) {
                if (this.edit) {
                  this.$emit("saveData", this.ruleForm);
                } else {
                  this.nextStep();
                }
              } else {
                this.$confirm("กรุณายืนยันว่าเลขนิติบุคคลถูกต้อง", "Warning", {
                  confirmButtonText: "ยืนยัน",
                  cancelButtonText: "แก้ไข",
                  type: "warning"
                })
                  .then(() => {
                    if (this.edit) {
                      this.$emit("saveData", this.ruleForm);
                    } else {
                      this.nextStep();
                    }
                  })
                  .catch(() => {
                    this.confirmReset = false;
                  });
              }
            })
            .finally(() => {
              this.companyLoading = false;
            });
        }
      });
    },
    saveEditdata() {
      this.loadingBtn = true;
      if (
        this.ruleForm.expectedPercentage == 5 &&
        this.ruleForm.expectedPercentageEtc == 0
      ) {
        this.$message({
          showClose: true,
          message: "ร้อยละที่คาดหวังว่าจะส่งออกได้ / ส่งออกได้เพิ่มขึ้น",
          type: "error"
        });
        this.loadingBtn = false;
      } else if (this.ruleForm.latestAnnualRevenues == 0) {
        this.$message({
          showClose: true,
          message: "กรุณากรอกรายได้ประจำปีล่าสุดของบริษัท (บาท)",
          type: "error"
        });
        this.loadingBtn = false;
      } else if (this.ruleForm.shipment == 2) {
        this.$message({
          showClose: true,
          message: "กรุณาแก้ไขวงเงินค้าขายต่อ Shipment โดยเฉลี่ย",
          type: "error"
        });
      } else if (
        this.ruleForm.exportValue == 1 ||
        this.ruleForm.exportValue == 2
      ) {
        this.$message({
          showClose: true,
          message: "กรุณาแก้ไขมูลค่าการส่งออกโดยเฉลี่ยต่อปี",
          type: "error"
        });
      } else {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.$emit("emitData", this.ruleForm);
            setTimeout(() => {
              this.loadingBtn = false;
            }, 300);
          } else {
            setTimeout(() => {
              this.loadingBtn = false;
            }, 300);
            this.$message({
              showClose: true,
              message: "กรุณากรอกข้อมูลให้ครบ",
              type: "error"
            });
            this.confirmReset = false;
          }
        });
      }
    },
    nextStep() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$emit("nextStep");
        }
      });
    }
  }
};
</script>
<style scoped>
.el-form-item__content {
  line-height: 0px !important;
}

.fa-pencil-alt {
  font-size: 10px;
}
p {
  margin: 5px 0;
}
</style>

